import React from 'react'
import './Home.css'
import Personalsitepp from '../photos/personalsite-pp.jpg'

const Homepg = () => {
    return <div className="Web-Home-page">
        <div className="Web-Home-desc">
        <div className="greeting">Hello, my name is</div>
          
        <div className="name-title">Nicholas Lopez</div>
      
        <div className="intro">I build things online for the <span className="self-title">Web</span>.</div>
        
        <div className="desc">I am a software developer specializing in building and designing exceptional digital experiences.</div>
          
             <div className="desc">
             Currently, I am working with a great team of developers at ZagHop under MMIT,</div>
       
             <div className="desc">
                 helping to create new digital products under the full utilization of Salesforce/Veeva.</div>
        
        </div>
    </div>
}

export default Homepg;