import React from 'react'
import './Contact.css'

const ContactFooter = () => {
    return <div className="Contact-Footer">
        <span className="intOut">Get in touch with me</span>
        <br />
        <span className="outro">My inbox is always open, whether you have a question
        <br /> or even if you just want to say hi!
        <br />
            I'll always try my best to get back to you!
        </span>
        <div className="contact-button"
        style={{boxSizing: "border-box"}}
         onClick={() => 
            window.location = 'mailto:nrlopez16@hotmail.com'}>Say Hello</div>
        <span className="footer">Created and designed by 
        <span className="footer-name"> Nicholas Lopez</span></span>
    </div>
}

export default ContactFooter;