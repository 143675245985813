import React from 'react'
import './Projects.css'
import MMIT from '../photos/MMITcontract-holder.png'
import DH from '../photos/DH-Face.png'

const Projectspg = () => {
    return <div className="Web-Project-page">
        <span className="page-title">Some of the work I have done:</span>
        <div className="Project">
            <img className="Project-image first" 
            src={MMIT}></img>
            <div className="background"></div>
            <div className="main-content"><div className="Project-title">Pharma Temp Holder</div>
            <div className="Web-Project-desc"> 
            
            A minimalist template holder, where I have half of all the templates I have 
            created for a handfull of pharmacuetical companies to use with Veeva/Salesforce.
            Each template has an email friendly version of it that is not in this holder, and 
            most of these templates were made to never be used for screens smaller than an iPad.
         </div>
            <span className="tech-stack">Tech used to create: 
            <span> VueJS</span>
            <span> JavaScript</span>
            <span> Mustache.js/Handlebar.js</span>
            </span>
            <ul className="deploy-links">
                <a href="https://github.com/Nrlopez16/PharmaTemps">
            <i class="fa-brands fa-github fa-1x" 
            src="https://github.com/Nrlopez16/PharmaTemps"></i>
            </a>
            <a href="https://idyllic-fox-0a626c.netlify.app/">
            <i class="fa-solid fa-arrow-up-right-from-square fa-1x"></i>
            </a>
            </ul>
            </div>
        </div>
        <div className="Project">
            <img className="Project-image second" 
            src={DH}></img>
            <div className="background"></div>
            <div className="main-content even-size">
                <div className="Project-title">DigitalHorizen Landing</div>
            <div className="Web-Project-desc"> 
            A heavily planned out frontend contact page utilized for freelance marketing 
            between a group of developers. Created with heavy responsiveness in mind, 
            this site is friendly to all screen sizes whether you are on mobile or on a desktop,
            we have it all here. Drop by to leave a message if you'd like!
            </div>
            <span className="tech-stack">Tech used to create: 
            <span> ReactJS</span>
            <span> JavaScript</span>
            <span> SASS</span>
            <span> Cloudinary</span>
            <span> Framer Motion</span>
            </span>
            <ul className="deploy-links">
                <a href="https://github.com/Nrlopez16/personal_V2">
            <i class="fa-brands fa-github fa-1x" 
            src="https://github.com/Nrlopez16/personal_V2"></i>
            </a>
            <a href="https://digitalhorizen.com/">
            <i class="fa-solid fa-arrow-up-right-from-square fa-1x"></i>
            </a>
            </ul>
            </div>
        </div>
        <div className="Project">
            <img className="Project-image" 
            src="https://t4.ftcdn.net/jpg/02/63/83/49/360_F_263834991_Mnn2Gpw32dM11jEyoIjduze48K1idNZq.jpg"></img>
            <div className="background"></div>
            <div className="main-content"><div className="Project-title">Coming Soon...</div>
            <div className="Web-Project-desc"> 
        
            Since I have been busy working for the past few years, I unfortunately have not given
            myself the chance to really stretch and show off my skills in personal projects, so I am currently 
            working on a few that I am proud of and will be unveiling shortly!
            </div>
            <ul className="deploy-links">
            <i class="fa-brands fa-github fa-1x" ></i>
            <i class="fa-solid fa-arrow-up-right-from-square fa-1x"></i>
            </ul>
            </div>
        </div>
    </div>
}

export default Projectspg;