import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './srcstyles.css'

import {
  App
} from './components';


ReactDOM.render(
  <App />,
  document.getElementById('root')
);