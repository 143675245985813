import React, {useState} from 'react'
import './jobHist.css'



const JobHistory = () => {
    const [zaghopActive, setZaghopActive] = useState(true);
    const [backyardActive, setBackyardActive] = useState(false);
    const [dhActive, setDhActive] = useState(false);


    return <div className="jobHist-page">
        <div className="top-head">
        <span className="exp-page-title">Where I've Worked</span>
        <div className="title-line"></div>
        </div>

<div className="history-container">
    <div className="company-container">
        <ul>
            <li className={zaghopActive ? "clicked" : " "}
             onClick={() => {
                console.log('clicked Zaghop');
                setZaghopActive(true);
                setBackyardActive(false);
                setDhActive(false);
                }}>Zaghop</li>
            <li className={backyardActive ? "clicked" : " "}
             onClick={() => {
                console.log('clicked BackYard');
                setZaghopActive(false);
                setBackyardActive(true);
                setDhActive(false);
                }}>BackYard</li>
            <li className={dhActive ? "clicked" : " "}
             onClick={() => {
                console.log('clicked DigitalHorizen');
                setZaghopActive(false);
                setBackyardActive(false);
                setDhActive(true);
                }}>DigitalHorizen</li>
        </ul>
    </div>
    <div className="job-container">

        <div className={zaghopActive ? "Job active" : "Job"}>
            <div className="exp-main-content">
                <p className="title-cont">
                    <span className="job-title">React/JavaScript Developer</span>
                    <nobr>
                    <span className="company-title">
                        <span className="at-sign"> &#64; </span>
                        Zaghop</span></nobr>
                </p>
                <p className="longevity">April 2022 - Present</p>
                <ul className="job-desc-list">
                    <li className="desc-li">Developed React.js / Javascript applications based on the demands of our clients.</li>
                    <li className="desc-li">Utilized Xcode to run Veeva framework / HTML templates in a simulated iOS environment.</li>
                    <li className="desc-li">Created custom HTML templates using Mustache.js / Handlebar.js and CSS.</li>
                    <li className="desc-li">Developed Email compatible versions of each template for the clients to utilize.</li>
                </ul>
            </div>
        </div>



        <div className={backyardActive ? "Job active" : "Job"}>
            <div className="exp-main-content">
                <p className="title-cont">
                    <span className="job-title">Software Engineer / Front-End Lead</span>
                    <nobr>
                    <span className="company-title">
                        <span className="at-sign"> &#64; </span>
                        Backyard Software Co.</span></nobr>
                </p>
                <p className="longevity">March 2021 - May 2022</p>
                <ul className="job-desc-list">
                    <li className="desc-li">Oversee the production of the product in a startup based environment.</li>
                    <li className="desc-li">Manage a team of developers in a remote environment to scale the usability and functionality of the site.</li>
                    <li className="desc-li">Quickly troubleshoot an issue and streamline the process to resolve it.</li>
                    <li className="desc-li">Experience with project management tools to delegate tasks accordingly.</li>
                </ul>
            </div>
        </div>


        <div className={dhActive ? "Job active" : "Job"}>
            <div className="exp-main-content">
                <p className="title-cont">
                    <span className="job-title">Freelance Web Developer</span>
                    <nobr>
                    <span className="company-title">
                        <span className="at-sign"> &#64; </span>
                        Self Employed</span></nobr>
                </p>
                <p className="longevity">October 2019 - Present</p>
                <ul className="job-desc-list">
                    <li className="desc-li">Develop and design the front end framework for local businesses based on deadlines and client requests.</li>
                    <li className="desc-li">Quickly translated business needs into well thought out web pages. Made adjustments when needed to meet client expectations on appearance and timelines.</li>
                </ul>
            </div>
        </div>

</div>
</div>
    </div>
}

export default JobHistory;