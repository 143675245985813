import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Homepg,
  Projectspg,
  Aboutpg,
  JobHistory,
  ContactFooter
} from './index';
import { Link } from "react-scroll";
import { Element } from "react-scroll";
import './App.css'
import Logo from '../photos/DigitalHorizen-logo.jpg'
import Resume2023NL from '../photos/Resume2023NL.pdf'

const duration = 500;

const App = () => {
  const [active, setActive] = useState(false);

    const handleClickInsta = () => {
        window.location.assign('https://www.instagram.com/starprince98/')
    }
    const handleClickGit = () => {
        window.location.assign('https://github.com/Nrlopez16')
    }
    const handleClickLinkedIn = () => {
        window.location.assign('https://www.linkedin.com/in/nicholas-lopez1/')
    }
    const handleClickTwitter = () => {
        window.location.assign('https://twitter.com/StarPrince98')
    }
    const handleResume = () => {
      window.open(Resume2023NL)
    }


  return <div className="MainApp">


  <div className="option-bar">
   <div className="About-Option">
    <Link activeClass="active" to="section-1-app" spy smooth duration={duration}
     onClick={()=>{
      setActive(!active)
      console.log('active state', active)
      }}>
        About
      </Link>
      </div>
   <div className="Experience-Option">
        <Link activeClass="active" to="section-2-app" spy smooth duration={duration}
         onClick={()=>{
          setActive(!active)
          console.log('active state', active)
          }}>
        Experience
      </Link>
      </div>
   <div className="Projects-Option">
        <Link activeClass="active" to="section-3-app" spy smooth duration={duration}
         onClick={()=>{
          setActive(!active)
          console.log('active state', active)
          }}>
        Projects
      </Link>
      </div>
   <div className="Contact-Option">
        <Link activeClass="active" to="section-4-app" spy smooth duration={duration}
         onClick={()=>{
          setActive(!active)
          console.log('active state', active)
          }}>
        Contact
      </Link>
      </div>
  </div>
    <div className={active ? "Head-Bar open" : "Head-Bar"}>
      <div className="App-Header">
        <div className={active ? "menu-btn open" : "menu-btn"} onClick={()=>{
          setActive(!active)
          console.log('active state', active)
          }}>
           <div className="menu-btn-burger"></div>
       </div>
        <img className="App-Logo-image" src={Logo} />
      </div>
          <div className="app-content">
       <Homepg />
      <Element className="section" name="section-1-app">
       <Aboutpg />
      </Element>
      <Element className="section" name="section-2-app">
      <JobHistory />
      </Element>
      <Element className="section" name="section-3-app">
       <Projectspg />
      </Element>
      <Element className="section" name="section-4-app">
       <ContactFooter />
      </Element>
          </div>
     <div className="WebNav">
        <img className="Web-Logo-image" src={Logo} />
       
      <ul className="WebNav-List">
        <li className="WebNav-Link">
        <Link activeClass="active" to="section-1" spy smooth duration={duration}>
        About
      </Link>
          </li>
        <li className="WebNav-Link">
        <Link activeClass="active" to="section-2" spy smooth duration={duration}>
        Experience
      </Link>
          </li>
        <li className="WebNav-Link">
        <Link activeClass="active" to="section-3" spy smooth duration={duration}>
        Projects
      </Link>
          </li>
        <li className="WebNav-Link">
        <Link activeClass="active" to="section-4" spy smooth duration={duration}>
        Contact
      </Link>
          </li>
        <div className="WebNav-Button" 
        style={{boxSizing: "border-box"}}
        onClick={handleResume.bind(this)}>Resume</div>
      </ul>
     </div>      
    </div>
    <ul className="social-list">
      <i class="fa-brands fa-github fa-2x" onClick={handleClickGit.bind(this)}></i>
      <i class="fa-brands fa-instagram fa-2x" onClick={handleClickInsta.bind(this)}></i>
      <i class="fa-brands fa-twitter fa-2x" onClick={handleClickTwitter.bind(this)}></i>
      <i class="fa-brands fa-linkedin fa-2x" onClick={handleClickLinkedIn.bind(this)}></i>
        <div className="social-line"></div>
    </ul>
    <a className="email-contact" href="mailto:nrlopez16@hotmail.com"
    >nrlopez16@hotmail.com<div className="contact-line"></div></a>
    <div className="web-view">
       <Homepg />
      <Element className="section" name="section-1">
       <Aboutpg />
      </Element>
      <Element className="section" name="section-2">
      <JobHistory />
      </Element>
      <Element className="section" name="section-3">
       <Projectspg />
      </Element>
      <Element className="section" name="section-4">
       <ContactFooter />
      </Element>
       </div>
</div>
  
}

export default App;