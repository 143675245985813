import React from 'react'
import './About.css'
import Personalsitepp from '../photos/profile-renew.jpg'

const Aboutpg = () => {
    return <div className="Web-About-page">
        <span className="Web-About-desc">
            <span className="Web-About-Title">About Me</span><div className="About-Line"></div>
        <br />
        My interest 
        in web development started in 2014 when I decided on a whim 
        <br />
        to try my hand at making a web based game,
        turned out I was really good at it and I enjoyed it quite alot!
        <br />
        <br /> 
        Fast forward to today, and i've had the priviledge to do alot of work in a startup 
        environment, 
        building the future along with many other great minds. Happily learning more and more 
        along the way aswell as every day goes on.
        <br />
        <br />
        I also do freelance work for anyone that is interested (Hit me up!)
        <br /> 
        <br />
        <span>Here are a few technologies I've been working with recently:</span>
        <ul className="tech-list">
            <li>Javascript</li>
            <li>React</li>
            <li>Amazon S3/Dynamo</li>
            <li>Node.js</li>
            <li>MongoDb</li>
            <li>SQL</li>
            <li>WordPress</li>
            <li>Python</li>
        </ul>
        </span>
        <img className="personal-photo" src={Personalsitepp} />
    </div>
}

export default Aboutpg;